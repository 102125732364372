<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <strong>
          </strong> Archived Job Sheets
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details

          ></v-text-field>
        </v-card-title>

        <v-data-table
            :headers="headers"
            :items="jobSheets"
            item-key="id"
            class="elevation-1 table-one"
            multi-sort
            :search="search"
            v-model="selectedRows"
            show-select
        >

          <template v-slot:item.action="{item}">
            <div class="d-flex">
              <v-card-title>
                <v-btn
                    color="green"
                    dark
                    outlined
                    @click.stop="viewJobSheet(item.id)">
                  View Details
                </v-btn>
              </v-card-title>
              <v-card-title>
                <v-btn
                    color="red"
                    dark
                    outlined
                    @click="delRecord(item.id)">
                  Delete
                </v-btn>
                <v-btn
                    color="blue"
                    dark
                    outlined
                    @click="unArchiveRecord(item)">
                  <v-icon
                      dark
                  >
                    mdi-archive-minus
                  </v-icon>
                </v-btn>
              </v-card-title>
            </div>
          </template>
        </v-data-table>
      </base-card>
    </v-col>

    <ConfirmDialog ref="confirmDelete" />
  </v-row>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import ConfirmDialog from "@/components/shared/ConfirmDialog";


export default {
  name: "JobSheets",
  components: {
    ConfirmDialog
  },
  data() {
    return {
      search: '',
      items: [],
      selectedRows: [],
      headers: [
        {
          text: 'Job Number',
          sortable: true,
          value: 'jobNumber',
          align: 'left',
        },
        {
          text: 'Order Number',
          sortable: true,
          value: 'orderNumber',
          align: 'left',
        },
        {
          text: 'Engineer',
          sortable: true,
          value: 'engineer',
          align: 'left',
        },
        {
          text: 'Work Date',
          sortable: true,
          value: 'workDate',
          align: 'middle',
        },
        {
          text: 'Customer',
          sortable: true,
          value: 'customerName',
          align: 'middle',
        },
        {
          text: 'Actions',
          value: 'action',
        }
      ],
    }
  },
  computed: {
    ...mapState('job-sheets', {loadingJobSheets : 'isFindPending'}),
    ...mapGetters('job-sheets', {findJobSheetsInStore : 'find'}),

    jobSheets() {
      return this.findJobSheetsInStore({ query: {archived: { $ne: 0 }}}).data || [];
    },
    selectedJobSheetIds() {
      return this.selectedRows.map(a => a.id);
    }
  },
  watch: {
    search(newSearchTerm) {
      localStorage.jobSheetIndexSearch = newSearchTerm;
    }
  },
  methods: {
    ...mapActions('job-sheets', { findJobSheets: 'find' }),
    deleteItem(id) {
      this.$store.dispatch('job-sheets/remove', id)
    },
    async delRecord(id) {
      if (
          await this.$refs.confirmDelete.open(
              "Confirm",
              "Are you sure you want to delete this record?"
          )
      ) {
        this.deleteItem(id);
      }
    },
    async unArchiveSelected() {
      for (const jobSheetId of this.selectedJobSheetIds) {
        const { JobSheet } = this.$FeathersVuex.api
        const thisJobSheet = await JobSheet.get(jobSheetId)
        thisJobSheet.archived = 0;
        thisJobSheet.patch()
      }
    },
    async unArchiveRecord(item) {
      item.archived = 0;
      item.patch();
    },
    viewJobSheet(id) {
      this.$router.push('/app/jobsheetview/'+id)
    },
    getJobSheets(){
      fetch('https://emissis-app.com/api/reports/jobSheet?jobSheetIds='+this.selectedJobSheetIds, {
        method: 'GET',
        headers: {"Authorization": "Bearer " + this.$store.state.auth.accessToken}
      }).then((res) => {
        console.log(res);
        window.location = 'https://emissis-app.com/api/assetList.pdf'
      });
      console.log(this.selectedJobSheetIds);
    },
  },
  mounted() {
    this.findJobSheets();
    if (localStorage.jobSheetIndexSearch) {
      this.search = localStorage.jobSheetIndexSearch;
    }
  }
}
</script>

<style scoped>

</style>
